<template>
  <div class="customer-fund-exchange">
    <div class="operation-bar">
      <a-space size="middle">
        <a-button v-if="false"  type="link" :href="excelurl" target="_blank" class="doc-link">
          <template #icon><file-text-outlined /></template>
          <span>云文档地址</span>
        </a-button>
        <a-divider v-if="false"  type="vertical" />
        <a-button           
          type="primary" 
          @click="onBatchOperationClickFeishu('MIFIsRpLzh7MUZtLHaQc15PHnTc')"
          class="exchange-btn"
        >
          <template #icon><sync-outlined /></template>
          <span>云文档资金置换</span>
        </a-button>
      </a-space>
    </div>

    <div class="iframe-container">
      <iframe :src="excelurl" frameborder="0"></iframe>
    </div>

    <a-modal
      title="资金置换"
      v-model:visible="feishuExcelVisible"
      :confirm-loading="feishuExcelConfirmLoading"
      :ok-button-props="feishuOkButtonDisabled"
      :cancel-button-props="feishuCancelButtonDisabled"
      :cancel-text="cancelText"
      :ok-text="okText"
      @ok="feishuExcelhandleOk"
      @cancel="feishuExcelhandleCancel"
      width="500px"
      class="fund-exchange-modal"
    >
      <div class="modal-content">
        <p class="status-message" :class="{ 'error': feishuMsg.includes('失败'), 'success': feishuMsg.includes('完成') }">
          {{ feishuMsg }}
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { message } from 'ant-design-vue';
import axios from '@/utils/axios';
import { FileTextOutlined, SyncOutlined } from '@ant-design/icons-vue';

export default {
  name: 'CustomerFundExchange',
  components: {
    FileTextOutlined,
    SyncOutlined
  },
  data() {
    return {
      excelurl: 'https://lmadart.feishu.cn/sheets/MIFIsRpLzh7MUZtLHaQc15PHnTc',
      feishuExcelVisible: false,
      feishuExcelConfirmLoading: false,
      feishuOkButtonDisabled: { disabled: false },
      feishuCancelButtonDisabled: { disabled: false },
      feishuExcelflag: "",
      cancelText: '关闭',
      okText: '确定',
      excelurlName: '请点击查看：',
      feishuMsg: ''
    };
  },
  methods: {
    onBatchOperationClickFeishu(flag) {
      this.feishuExcelVisible = true;
      this.feishuExcelflag = flag;
      this.feishuMsg = '确认执行资金置换操作？';
    },
    feishuExcelhandleOk() {
      this.feishuCancelButtonDisabled = {disabled: true};
      this.feishuExcelConfirmLoading = true;
      this.feishuMsg = '【资金置换】任务已经提交，请耐心等待执行结果...';
      
      axios.post('/feishu_recharge/excel_fund_exchange/' + this.feishuExcelflag)
        .then(res => {
          this.feishuExcelConfirmLoading = false;
          if (res.code === 0) {
            this.feishuMsg = '【资金置换】任务执行完成，请在在线文档中查看执行结果。';
          } else {
            this.feishuMsg = `【资金置换】任务执行失败：${res.msg}`;
          }
          this.feishuOkButtonDisabled = { disabled: true };
          this.feishuCancelButtonDisabled = { disabled: false };
        })
        .catch(() => {
          this.feishuExcelConfirmLoading = false;
          this.feishuMsg = '【资金置换】任务提交失败，请关闭后重新提交！';
          this.feishuOkButtonDisabled = { disabled: true };
          this.feishuCancelButtonDisabled = { disabled: false };
        });
    },
    feishuExcelhandleCancel() {
      this.feishuExcelVisible = false;
      this.feishuMsg = '';
      this.feishuOkButtonDisabled = { disabled: false };
    }
  }
};
</script>

<style lang="less" scoped>
.customer-fund-exchange {
  padding: 24px;

  .operation-bar {
    margin-bottom: 24px;
    background: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .doc-link {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }
  }

  .iframe-container {
    width: 100%;
    height: calc(100vh - 180px);
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.fund-exchange-modal {
  .modal-content {
    padding: 16px;
    text-align: center;

    .status-message {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      padding: 16px;
      border-radius: 4px;
      background-color: #f6f6f6;
      transition: all 0.3s ease;

      &.success {
        color: #52c41a;
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
      }

      &.error {
        color: #ff4d4f;
        background-color: #fff2f0;
        border: 1px solid #ffccc7;
      }
    }
  }
}
</style>